<template>
  <v-container fluid fill-height>
    <v-row
      align="center"
      justify="center"
      no-gutters
    >
      <v-col cols="12" xs="12" sm="8" md="4">
        <!-- <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Login form</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="form.email"
                v-validate="'required|email'"
                :error-messages="errors.collect('email')"
                data-vv-name="email"
                required
                prepend-icon="email"
                name="email"
                label="email"
                type="text"
              ></v-text-field>
              <v-text-field
                v-model="form.password"
                v-validate="'required|min:4|max:12'"
                :error-messages="errors.collect('password')"
                data-vv-name="password"
                required
                prepend-icon="lock"
                name="password"
                label="Password"
                type="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="logOn">Login</v-btn>
          </v-card-actions>
        </v-card> -->

        <!-- 로딩시 프로그레스 바는 안돌아가서 스켈레톤 로더로 변경 -->
        <v-sheet
          color="grey lighten-4"
          class="px-3 pt-3 pb-3"
        >
          <v-skeleton-loader
            class="mx-auto"
            max-width="500"
            type="card, date-picker"
          ></v-skeleton-loader>
        </v-sheet>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  data: () => ({
  }),

  mounted () {
    // 중요: 백엔드에서 /logoin?token=12313123.... 로 넘겨준 토큰을 받기 위해
    if (this.$route.query) { // this.$route.query 는 항시 있으니 참이구만
      if (this.$route.query.token) {
        this.loginWithToken(this.$route.query.token)
      } else {
        this.redirect('/')
      }
    }
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // 구분: 백앤드에서 넘어오는 토큰을 받아서 localStorage, vuex 에 담는다.
    // 중요: 이후 1번만 리로딩을 시킴으로써 DB 의 변경사항을 반영시킨다!
    async loginWithToken (token) {
      try {
        if (token) {
          // 토큰 저장 + 토큰을 푼 유저정보를 저장
          await this.$store.dispatch('LOGIN', { token })
          // 유저정보 패칭 - 제대로 vuex 에 저장되었는지 체크
          const ui = this.$store.state.ui
          // ui 와 dbcode 가 있으면 정상이므로 리다이렉트!
          if (ui && ui.dbcode) {
            // 중요: localStorage 를 사용해서 1번만 리로딩 시킨다!
            if (localStorage.getItem('reloaded')) {
              localStorage.removeItem('reloaded')

              // * [2021.4.22] DB에서 할일목록 가져와서 vuex todo.state.todos 에 채우기
              const { data } = await this.$axios.post('lawork/member/getTodo', { email: ui.email, dbcode: ui.dbcode })
              if (!data.success) throw new Error('할일목록을 검색할 수 없습니다.')
              if (!data.todo) throw new Error('정상적인 할일목록이 아닙니다.')

              // * [2021.4.22] data.todo 에 대한 JSON.parse() 에러시 대응
              // 구문에러는 정상적인 JSON 문자열이 아닌경우 발생하는 파싱에러임.
              // 이경우 그냥 스킵하도록 처리 함. 다른 에러는 에러를 전달함
              try {
                const todos = JSON.parse(data.todo)
                if (todos && typeof todos === 'object') {
                  // * 정상인 경우는 vuex vuex todos 에 채우기
                  this.$store.dispatch('todo/setTodos', { todos })
                }
              } catch (e) {
                // 구문에러가 나면 아무것도 안함
                if (e instanceof SyntaxError) {
                  // console.log('구문에러')
                } else {
                  // 구문에러 이외의 에러는 에러를 전달한다
                  throw new Error(e)
                }
              }

              setTimeout(() => {
                this.redirect('/schedule') // 일정으로 리다이렉트
              }, 300)
            } else {
              localStorage.setItem('reloaded', '1')
              location.reload()
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  },
  props: {
    source: String
  }
}
</script>
